import { ProjectLanguage } from "./enums";
import { assertNever } from "./errors";

// !!! IMPORTANT !!! This needs to be kept in sync with @hex/python-kernel/common/init.py  and @hex/python-kernel-startup/python_kernel_startup/hex_dynamic_value.py
export const HEX_PRIVATE_PREFIX = "_hex_";

export const HEX_PRIVATE_PREFIX_R = ".hex_";

/**
 * Use for variables that should be added as a scope item but not shown to the
 * user
 */
export const HEX_HIDDEN_PREFIX = `${HEX_PRIVATE_PREFIX}hidden_`;

const PREFIX_MAP = {
  [ProjectLanguage.PYTHON]: HEX_PRIVATE_PREFIX,
  [ProjectLanguage.R]: HEX_PRIVATE_PREFIX_R,
} as const;
export type HexPrivatePrefix = (typeof PREFIX_MAP)[keyof typeof PREFIX_MAP];

export const getHexPrivatePrefix = (
  language: ProjectLanguage,
): HexPrivatePrefix => PREFIX_MAP[language];

export const getEnvVarSource = (
  language: ProjectLanguage,
  name: string,
  value: string,
): string => {
  // We're just using JSON.stringify as a way to essentially escape the strings
  const escapedName = JSON.stringify(name);
  const escapedValue = JSON.stringify(value);

  switch (language) {
    case ProjectLanguage.PYTHON:
      return `_hex_os.environ[${escapedName}] = ${escapedValue}`;
    case ProjectLanguage.R:
      return `Sys.setenv(${escapedName} = ${escapedValue})`;
    default:
      assertNever(language, language);
  }
};
