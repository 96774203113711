import { assertNever } from "../errors";

import {
  MapAreaLayer,
  MapCoordinateData,
  MapCoordinateDataLatLng,
  MapCoordinateDataLatLngSeparate,
  MapCoordinateDataLngLat,
  MapCoordinateSystem,
  MapDataArea,
  MapDataCoordinate,
  MapDataDataset,
  MapDatasetLayer,
  MapHeatmapLayer,
  MapLayer,
  MapLayerType,
  MapLayerTypeArea,
  MapLayerTypeDataset,
  MapLayerTypeHeatmap,
  MapLayerTypeScatterplot,
  MapLayerTypeText,
  MapScatterplotLayer,
  MapTextLayer,
} from "./mapTypes";

export function visitMapLayer<T>(
  layer: MapLayer,
  visitor: {
    [MapLayerType.scatter]: (layer: MapScatterplotLayer) => T;
    [MapLayerType.text]: (layer: MapTextLayer) => T;
    [MapLayerType.area]: (layer: MapAreaLayer) => T;
    [MapLayerType.heatmap]: (layer: MapHeatmapLayer) => T;
    [MapLayerType.dataset]: (layer: MapDatasetLayer) => T;
  },
): T {
  const layerType = layer.type;
  switch (layerType) {
    case MapLayerType.scatter:
      return visitor[MapLayerType.scatter](layer);
    case MapLayerType.text:
      return visitor[MapLayerType.text](layer);
    case MapLayerType.area:
      return visitor[MapLayerType.area](layer);
    case MapLayerType.heatmap:
      return visitor[MapLayerType.heatmap](layer);
    case MapLayerType.dataset:
      return visitor[MapLayerType.dataset](layer);
    default:
      assertNever(layerType, layerType);
  }
}

export function visitMapLayerType<T>(
  layerType: MapLayerType,
  visitor: {
    [MapLayerType.scatter]: (layerType: MapLayerTypeScatterplot) => T;
    [MapLayerType.text]: (layerType: MapLayerTypeText) => T;
    [MapLayerType.area]: (layer: MapLayerTypeArea) => T;
    [MapLayerType.heatmap]: (layer: MapLayerTypeHeatmap) => T;
    [MapLayerType.dataset]: (layer: MapLayerTypeDataset) => T;
  },
): T {
  switch (layerType) {
    case MapLayerType.scatter:
      return visitor[MapLayerType.scatter](layerType);
    case MapLayerType.text:
      return visitor[MapLayerType.text](layerType);
    case MapLayerType.area:
      return visitor[MapLayerType.area](layerType);
    case MapLayerType.heatmap:
      return visitor[MapLayerType.heatmap](layerType);
    case MapLayerType.dataset:
      return visitor[MapLayerType.dataset](layerType);
    default:
      assertNever(layerType, layerType);
  }
}

export function visitMapData<T>(
  mapData: MapDataCoordinate | MapDataArea | MapDataDataset,
  visitor: {
    coordinate: (data: MapDataCoordinate) => T;
    area: (data: MapDataArea) => T;
    dataset: (data: MapDataDataset) => T;
  },
): T {
  if (MapDataCoordinate.guard(mapData)) {
    return visitor.coordinate(mapData);
  } else if (MapDataDataset.guard(mapData)) {
    return visitor.dataset(mapData);
  } else if (MapDataArea.guard(mapData)) {
    // area data is the most permissive as all of its fields are optional
    // so check for this last
    return visitor.area(mapData);
  } else {
    assertNever(mapData, mapData);
  }
}

export function visitMapLayerCoordinates<T>(
  coordinates: MapCoordinateData,
  visitor: {
    [MapCoordinateSystem.latlng]: (coordinates: MapCoordinateDataLatLng) => T;
    [MapCoordinateSystem.lnglat]: (coordinates: MapCoordinateDataLngLat) => T;
    [MapCoordinateSystem.latlng_separate]: (
      coordinates: MapCoordinateDataLatLngSeparate,
    ) => T;
  },
): T {
  const { system } = coordinates;
  switch (system) {
    case MapCoordinateSystem.latlng:
      return visitor[MapCoordinateSystem.latlng](coordinates);
    case MapCoordinateSystem.lnglat:
      return visitor[MapCoordinateSystem.lnglat](coordinates);
    case MapCoordinateSystem.latlng_separate:
      return visitor[MapCoordinateSystem.latlng_separate](coordinates);
    default:
      assertNever(system, system);
  }
}
