import { assertNever } from "../errors";
import { PyReference, pyReference } from "../shared-types/pyObjects";

import {
  MapCoordinateData,
  MapCoordinateSystemLatLng,
  MapCoordinateSystemLatLngSeparate,
  MapCoordinateSystemLngLat,
  MapLayer,
  MapLayerFill,
  MapLayerFillTypeDynamic,
  MapLayerFillTypeStatic,
  MapLayerSize,
  MapLayerSizeTypeDynamic,
  MapLayerSizeTypeStatic,
  MapLayerType,
} from "./mapTypes";
import { visitMapLayer } from "./mapVisitors";

export type MapReferencedDataFrame = {
  name: string;
  columns: readonly string[];
  locationColumns: readonly string[]; // columns used to identify location data
};

export interface MapReferencedDataFramesV2 {
  dataframeConfigs: readonly MapReferencedDataFrame[];
  dataframes: readonly PyReference[];
}

export function getMapReferencedDataFramesV2(
  layers: readonly MapLayer[],
): MapReferencedDataFramesV2 {
  const v1ReferencedDataFrames = getMapReferencedDataFrames(layers);
  const dataframes = v1ReferencedDataFrames.map(({ name }) =>
    pyReference(name),
  );

  return {
    dataframeConfigs: v1ReferencedDataFrames,
    dataframes,
  };
}

export function getMapReferencedDataFrames(
  layers: readonly MapLayer[],
): readonly MapReferencedDataFrame[] {
  const columnsByName: Map<string, Set<string>> = new Map();
  const locationColumnsByName: Map<string, Set<string>> = new Map();
  for (const layer of layers) {
    const name = getLayerDataFrameName(layer);
    if (name == null) {
      continue;
    }

    const columns: (string | undefined)[] = [
      ...(columnsByName.get(name) ?? []),
    ];
    const locationColumns: (string | undefined)[] = [
      ...(locationColumnsByName.get(name) ?? []),
    ];
    columns.push(...layer.tooltipDataFrameColumns);
    visitMapLayer(layer, {
      [MapLayerType.scatter]: (sLayer) => {
        locationColumns.push(
          ...getColumnsForCoordinates(sLayer.data.coordinates),
        );
        columns.push(...locationColumns);
        columns.push(getColumnForSize(sLayer.radius));
        if (sLayer.fill != null) {
          columns.push(getColumnForFill(sLayer.fill));
        }
      },
      [MapLayerType.text]: (tLayer) => {
        locationColumns.push(
          ...getColumnsForCoordinates(tLayer.data.coordinates),
        );
        columns.push(...locationColumns);
        columns.push(tLayer.text.dataFrameColumn);
      },
      [MapLayerType.area]: (areaLayer) => {
        locationColumns.push(areaLayer.data.dataFrameColumn);
        columns.push(...locationColumns);
        columns.push(areaLayer.text?.dataFrameColumn);
        if (areaLayer.fill != null) {
          columns.push(getColumnForFill(areaLayer.fill));
        }
      },
      [MapLayerType.heatmap]: (hmLayer) => {
        locationColumns.push(
          ...getColumnsForCoordinates(hmLayer.data.coordinates),
        );
        columns.push(...locationColumns);
        if (hmLayer.fill != null) {
          columns.push(getColumnForFill(hmLayer.fill));
        }
      },
      [MapLayerType.dataset]: (datasetLayer) => {
        locationColumns.push(datasetLayer.join?.dataFrameColumn);
        columns.push(...locationColumns);
        if (datasetLayer.fill != null) {
          columns.push(getColumnForFill(datasetLayer.fill));
        }
      },
    });

    columnsByName.set(
      name,
      new Set(columns.filter((c): c is string => c != null)),
    );
    locationColumnsByName.set(
      name,
      new Set(locationColumns.filter((c): c is string => c != null)),
    );
  }

  const dfs: MapReferencedDataFrame[] = [];
  for (const [name, columns] of columnsByName.entries()) {
    dfs.push({
      name,
      /* sort for stable array */
      columns: [...columns].sort(),
      locationColumns: [...(locationColumnsByName.get(name) ?? [])].sort(),
    });
  }

  return dfs;
}

export function getGeoJsonReferencedDataFrames(
  layers: readonly MapLayer[],
): readonly MapReferencedDataFrame[] {
  const columnsByName: Map<string, Set<string>> = new Map();
  for (const layer of layers) {
    if (layer.type !== MapLayerType.area || layer.data.dataFrameName == null) {
      continue;
    }

    const name = layer.data.dataFrameName;
    const columns: Set<string> = columnsByName.get(name) ?? new Set();
    if (layer.data.dataFrameColumn != null) {
      columns.add(layer.data.dataFrameColumn);
    }
    columnsByName.set(name, columns);
  }

  const dfs: MapReferencedDataFrame[] = [];
  for (const [name, columns] of columnsByName.entries()) {
    const sortedColumns = [...columns].sort(); /* sort for stable array */
    dfs.push({
      name,
      columns: sortedColumns,
      locationColumns: sortedColumns,
    });
  }

  return dfs;
}

export function getLayerDataFrameName(layer: MapLayer): string | undefined {
  return visitMapLayer(layer, {
    [MapLayerType.area]: (areaLayer) => areaLayer.data.dataFrameName,
    [MapLayerType.heatmap]: (heatmapLayer) => heatmapLayer.data.dataFrameName,
    [MapLayerType.scatter]: (scatterLayer) => scatterLayer.data.dataFrameName,
    [MapLayerType.text]: (textLayer) => textLayer.data.dataFrameName,
    [MapLayerType.dataset]: (datasetLayer) => datasetLayer.join?.dataFrameName,
  });
}

function getColumnsForCoordinates(
  coordinates: MapCoordinateData,
): readonly (string | undefined)[] {
  switch (coordinates.system) {
    case MapCoordinateSystemLatLngSeparate.value:
      return [coordinates.dataFrameLatColumn, coordinates.dataFrameLngColumn];
    case MapCoordinateSystemLatLng.value:
    case MapCoordinateSystemLngLat.value:
      return [coordinates.dataFrameColumn];
    default:
      assertNever(coordinates, coordinates);
  }
}

function getColumnForSize(size: MapLayerSize): string | undefined {
  switch (size.type) {
    case MapLayerSizeTypeStatic.value:
      return undefined;
    case MapLayerSizeTypeDynamic.value:
      return size.dataFrameColumn;
    default:
      assertNever(size, size);
  }
}

function getColumnForFill(fill: MapLayerFill): string | undefined {
  switch (fill.type) {
    case MapLayerFillTypeStatic.value:
      return undefined;
    case MapLayerFillTypeDynamic.value:
      return fill.dataFrameColumn;
    default:
      assertNever(fill, fill);
  }
}
