/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import { produce } from "immer";
import {
  Array,
  Boolean,
  Literal,
  Number,
  Optional,
  Record,
  Static,
  String,
  Tuple,
  Union,
} from "runtypes";

import { assertNever } from "../errors.js";
import { MapLayerId } from "../idTypeBrands";
import { getNormalEnum } from "../runtypeEnums";

// === THEMES ===

export const MapThemeHex = Literal("hex");
export const MapThemeDark = Literal("dark");
export const MapThemeLight = Literal("light");
export const MapThemeSatellite = Literal("satellite");
export const MapThemeStreets = Literal("streets");
export const MapThemeOutdoors = Literal("outdoors");

const MapThemeLiteral = Union(
  MapThemeHex,
  MapThemeDark,
  MapThemeLight,
  MapThemeSatellite,
  MapThemeStreets,
  MapThemeOutdoors,
);
export type MapTheme = Static<typeof MapThemeLiteral>;
export const MapTheme = getNormalEnum(MapThemeLiteral);

// === VIEW ===

export const MapInitialView = Record({
  latitude: Optional(Number),
  longitude: Optional(Number),
  zoom: Optional(Number),
  bearing: Optional(Number),
  pitch: Optional(Number),
  altitude: Optional(Number),
  maxPitch: Optional(Number),
  maxZoom: Optional(Number),
  minPitch: Optional(Number),
  minZoom: Optional(Number),
  width: Optional(Number),
  height: Optional(Number),
  normalize: Optional(Boolean),
  position: Optional(Array(Number)),
});
export type MapInitialView = Static<typeof MapInitialView>;

// === LAYER TYPES ===

export const MapLayerTypeScatterplot = Literal("scatter");
export type MapLayerTypeScatterplot = Static<typeof MapLayerTypeScatterplot>;

export const MapLayerTypeText = Literal("text");
export type MapLayerTypeText = Static<typeof MapLayerTypeText>;

export const MapLayerTypeArea = Literal("area");
export type MapLayerTypeArea = Static<typeof MapLayerTypeArea>;

export const MapLayerTypeHeatmap = Literal("heatmap");
export type MapLayerTypeHeatmap = Static<typeof MapLayerTypeHeatmap>;

export const MapLayerTypeDataset = Literal("dataset");
export type MapLayerTypeDataset = Static<typeof MapLayerTypeDataset>;

export const MapLayerTypeLiteral = Union(
  MapLayerTypeScatterplot,
  MapLayerTypeText,
  MapLayerTypeArea,
  MapLayerTypeHeatmap,
  MapLayerTypeDataset,
);
export type MapLayerType = Static<typeof MapLayerTypeLiteral>;
export const MapLayerType = getNormalEnum(MapLayerTypeLiteral);

// === COORDINATES ===

// = LAT LNG =

export const MapCoordinateSystemLatLng = Literal("latlng");
export type MapCoordinateSystemLatLng = Static<
  typeof MapCoordinateSystemLatLng
>;

export const MapCoordinateSystemLngLat = Literal("lnglat");
export type MapCoordinateSystemLngLat = Static<
  typeof MapCoordinateSystemLngLat
>;

export const MapCoordinateSystemLatLngSeparate = Literal("latlng_separate");
export type MapCoordinateSystemLatLngSeparate = Static<
  typeof MapCoordinateSystemLatLngSeparate
>;

export const MapCoordinateSystemLiteral = Union(
  MapCoordinateSystemLatLng,
  MapCoordinateSystemLngLat,
  MapCoordinateSystemLatLngSeparate,
);
export type MapCoordinateSystem = Static<typeof MapCoordinateSystemLiteral>;
export const MapCoordinateSystem = getNormalEnum(MapCoordinateSystemLiteral);

// === DATA ===

// = LAT LNG =

export const MapCoordinateDataLatLng = Record({
  system: MapCoordinateSystemLatLng,
  dataFrameColumn: Optional(String),
});
export type MapCoordinateDataLatLng = Static<typeof MapCoordinateDataLatLng>;

export const MapCoordinateDataLngLat = Record({
  system: MapCoordinateSystemLngLat,
  dataFrameColumn: Optional(String),
});
export type MapCoordinateDataLngLat = Static<typeof MapCoordinateDataLngLat>;

export const MapCoordinateDataLatLngSeparate = Record({
  system: MapCoordinateSystemLatLngSeparate,
  dataFrameLatColumn: Optional(String),
  dataFrameLngColumn: Optional(String),
});
export type MapCoordinateDataLatLngSeparate = Static<
  typeof MapCoordinateDataLatLngSeparate
>;

export const MapCoordinateData = Union(
  MapCoordinateDataLatLng,
  MapCoordinateDataLngLat,
  MapCoordinateDataLatLngSeparate,
);
export type MapCoordinateData = Static<typeof MapCoordinateData>;

export const MapDataCoordinate = Record({
  dataFrameName: Optional(String),
  coordinates: MapCoordinateData,
});
export type MapDataCoordinate = Static<typeof MapDataCoordinate>;

// = AREA =

const MapAreaDataSystemCustom = Literal("custom");
type MapAreaDataSystemCustom = Static<typeof MapAreaDataSystemCustom>;
const MapAreaDataSystemDataset = Literal("dataset");
type MapAreaDataSystemDataset = Static<typeof MapAreaDataSystemDataset>;
const MapAreaDataSystemLiteral = Union(
  MapAreaDataSystemCustom,
  MapAreaDataSystemDataset,
);
export const MapAreaDataSystem = getNormalEnum(MapAreaDataSystemLiteral);
export type MapAreaDataSystem = Static<typeof MapAreaDataSystemLiteral>;

// render "custom" areas, defined by selected data frame
export const MapDataAreaCustom = Record({
  system: Optional(MapAreaDataSystemCustom), // optional for backwards compat
  dataFrameName: Optional(String),
  dataFrameColumn: Optional(String),
});
// render data areas by joining existing dataset (e.g. counties) and selected data frame
export const MapDataAreaDataset = Record({
  system: MapAreaDataSystemDataset,
  dataFrameName: Optional(String),
  dataFrameColumn: Optional(String),
  datasetName: Optional(String),
  datasetField: Optional(String),
});
export const MapDataArea = Union(MapDataAreaCustom, MapDataAreaDataset);
export type MapDataArea = Static<typeof MapDataArea>;

// = DATASET =
export const MapDataDataset = Record({
  // only the dataset has as a type field currently as previous data
  // types were built (and have persisted data) before we knew that
  // we would have to support other data types...
  type: Literal("dataset"),
  datasetName: Optional(String),
});
export type MapDataDataset = Static<typeof MapDataDataset>;

// === COLOR ===

export const MapLayerColor = Tuple(Number, Number, Number);
export type MapLayerColor = Static<typeof MapLayerColor>;

export type MapLayerRGBA = [...MapLayerColor, number];

// === SIZE ===

export const MapLayerSizeTypeStatic = Literal("static");
export type MapLayerSizeTypeStatic = Static<typeof MapLayerSizeTypeStatic>;

export const MapLayerSizeTypeDynamic = Literal("dynamic");
export type MapLayerSizeTypeDynamic = Static<typeof MapLayerSizeTypeDynamic>;

export const MapLayerSizeTypeLiteral = Union(
  MapLayerSizeTypeDynamic,
  MapLayerSizeTypeStatic,
);
export const MapLayerSizeType = getNormalEnum(MapLayerSizeTypeLiteral);
export type MapLayerSizeType = Static<typeof MapLayerSizeTypeLiteral>;

export const MapLayerStaticSize = Record({
  type: MapLayerSizeTypeStatic,
  value: Number,
});
export type MapLayerStaticSize = Static<typeof MapLayerStaticSize>;

export const MapLayerDynamicSize = Record({
  type: MapLayerSizeTypeDynamic,
  dataFrameColumn: Optional(String),
  min: Number,
  max: Number,
});

export const MapLayerSize = Union(MapLayerStaticSize, MapLayerDynamicSize);
export type MapLayerSize = Static<typeof MapLayerSize>;

// === FILL ===

export const MapLayerFillTypeStatic = Literal("static");
export type MapLayerFillTypeStatic = Static<typeof MapLayerSizeTypeStatic>;

export const MapLayerFillTypeDynamic = Literal("dynamic");
export type MapLayerFillTypeDynamic = Static<typeof MapLayerSizeTypeDynamic>;

export const MapLayerFillTypeLiteral = Union(
  MapLayerFillTypeStatic,
  MapLayerFillTypeDynamic,
);
export type MapLayerFillType = Static<typeof MapLayerFillTypeLiteral>;
export const MapLayerFillType = getNormalEnum(MapLayerFillTypeLiteral);

const MapLayerBaseFill = Record({
  type: MapLayerFillTypeLiteral,
  opacity: Optional(Number), // optional to support backcompat
});

export const MapLayerStaticFill = MapLayerBaseFill.extend({
  type: MapLayerFillTypeStatic,
  color: Optional(MapLayerColor),
});
export type MapLayerStaticFill = Static<typeof MapLayerStaticFill>;

export const MapLayerDynamicFill = MapLayerBaseFill.extend({
  type: MapLayerFillTypeDynamic,
  dataFrameColumn: Optional(String),
  colors: Array(MapLayerColor),
});
export type MapLayerDynamicFill = Static<typeof MapLayerDynamicFill>;

export const MapLayerFill = Union(MapLayerDynamicFill, MapLayerStaticFill);
export type MapLayerFill = Static<typeof MapLayerFill>;

// === OUTLINE ===

export const MapLayerOutline = Record({
  color: Optional(MapLayerColor),
  width: Optional(Number),
});
export type MapLayerOutline = Static<typeof MapLayerOutline>;

// === TEXT ===

export const MapLayerTextAnchorLiteral = Union(
  Literal("start"),
  Literal("middle"),
  Literal("end"),
);
export type MapLayerTextAnchor = Static<typeof MapLayerTextAnchorLiteral>;
export const MapLayerTextAnchor = getNormalEnum(MapLayerTextAnchorLiteral);

export const MapLayerTextAlignmentLiteral = Union(
  Literal("top"),
  Literal("center"),
  Literal("bottom"),
);
export type MapLayerTextAlignment = Static<typeof MapLayerTextAlignmentLiteral>;
export const MapLayerTextAlignment = getNormalEnum(
  MapLayerTextAlignmentLiteral,
);

export const MapLayerText = Record({
  dataFrameColumn: Optional(String),
  size: Number,
  color: MapLayerColor,
  anchor: MapLayerTextAnchorLiteral,
  alignment: MapLayerTextAlignmentLiteral,
  backgroundColor: Optional(MapLayerColor),
});
export type MapLayerText = Static<typeof MapLayerText>;

// === AGGREGATION ===

export const MapAggregationTypeLiteral = Union(Literal("SUM"), Literal("MEAN"));
export type MapAggregationType = Static<typeof MapAggregationTypeLiteral>;
export const MapAggregationType = getNormalEnum(MapAggregationTypeLiteral);

// === DATASET JOIN ===
export const MapDatasetJoin = Record({
  dataFrameName: Optional(String),
  dataFrameColumn: Optional(String),
  datasetField: Optional(String),
});

// === LAYERS ===

const MapBaseLayer = Record({
  type: MapLayerTypeLiteral,
  id: MapLayerId,
  name: Optional(String),
  // interactions
  /** @deprecated, unused */
  autoHighlight: Optional(Boolean),
  tooltipDataFrameColumns: Array(String),
  // styles
  visible: Boolean, // defaults to true
  opacity: Number,
  highlightColor: Optional(MapLayerColor),
});

export const MapScatterplotLayer = MapBaseLayer.extend({
  type: MapLayerTypeScatterplot,
  data: MapDataCoordinate,
  // styles
  fill: Optional(MapLayerFill),
  outline: Optional(MapLayerOutline),
  radius: MapLayerSize,
});
export type MapScatterplotLayer = Static<typeof MapScatterplotLayer>;

export const MapTextLayer = MapBaseLayer.extend({
  type: MapLayerTypeText,
  data: MapDataCoordinate,
  text: MapLayerText,
});
export type MapTextLayer = Static<typeof MapTextLayer>;

export const MapAreaLayer = MapBaseLayer.extend({
  type: MapLayerTypeArea,
  data: MapDataArea,
  fill: Optional(MapLayerFill),
  outline: Optional(MapLayerOutline),
  pointRadius: MapLayerSize,
  text: Optional(MapLayerText),
});
export type MapAreaLayer = Static<typeof MapAreaLayer>;

export const MapHeatmapLayer = MapBaseLayer.extend({
  type: MapLayerTypeHeatmap,
  data: MapDataCoordinate,
  aggregationType: MapAggregationTypeLiteral,
  size: Number,
  fill: MapLayerDynamicFill,
});
export type MapHeatmapLayer = Static<typeof MapHeatmapLayer>;

export const MapDatasetLayer = MapBaseLayer.extend({
  type: MapLayerTypeDataset,
  data: MapDataDataset,
  fill: Optional(MapLayerFill),
  outline: Optional(MapLayerOutline),
  join: Optional(MapDatasetJoin),
});
export type MapDatasetLayer = Static<typeof MapDatasetLayer>;

export const MapLayer = Union(
  MapScatterplotLayer,
  MapTextLayer,
  MapAreaLayer,
  MapHeatmapLayer,
  MapDatasetLayer,
);
export type MapLayer = Static<typeof MapLayer>;

// === MAP ===

export const Map = Record({
  layers: Array(MapLayer),
  initialView: Optional(MapInitialView),
  theme: MapThemeLiteral,
  isLegendOpen: Optional(Boolean),
});
export type Map = Static<typeof Map>;

/** Returns a new Map cell config that is cleaned up for Hex yml export. */
export function cleanMapForExport(map: Map): Map {
  return produce(map, (draft) => {
    for (const layer of draft.layers) {
      if ("coordinates" in layer.data) {
        layer.data.coordinates = cleanCoordinateDataForExport(
          layer.data.coordinates,
        );
      }
    }
  });
}

// When switching between coordinate systems,
// parameters from the previously selected system seem to sometimes be persisted.
// This function cleans the coordinate data so those outdated values don't get exported.
// See https://linear.app/hex/issue/EXP-905/map-cells-exporting-invalid-params-to-yaml-which-error-on-import
function cleanCoordinateDataForExport(
  coordinates: MapCoordinateData,
): MapCoordinateData {
  if (coordinates.system === MapCoordinateSystem.latlng) {
    return {
      system: coordinates.system,
      dataFrameColumn: coordinates.dataFrameColumn,
    };
  } else if (coordinates.system === MapCoordinateSystem.lnglat) {
    return {
      system: coordinates.system,
      dataFrameColumn: coordinates.dataFrameColumn,
    };
  } else if (coordinates.system === MapCoordinateSystem.latlng_separate) {
    return {
      system: coordinates.system,
      dataFrameLatColumn: coordinates.dataFrameLatColumn,
      dataFrameLngColumn: coordinates.dataFrameLngColumn,
    };
  } else {
    assertNever(coordinates, coordinates);
  }
}
