import {
  Array,
  Literal,
  Null,
  Record as RRecord,
  Static,
  String,
  Union,
} from "runtypes";

import { EnumValues, OrgRole } from "./enums";
import { isOrgRoleSuperset } from "./orgRole";
import { getNormalEnum } from "./runtypeEnums";

export const LANGUAGE_SKIPPED = "SKIPPED";

export type LanguageSkipped = typeof LANGUAGE_SKIPPED;

export const LanguagePreferenceLiteral = Union(
  Literal("SQL"),
  Literal("SQL_AND_PYTHON"),
  Literal("NO_CODE"),
);

export const LanguagePreferenceSource = {
  ONBOARDING: "ONBOARDING",
} as const;
export type LanguagePreferenceSource = EnumValues<
  typeof LanguagePreferenceSource
>;

export const LanguagePreference = getNormalEnum(LanguagePreferenceLiteral);

export type LanguagePreference = Static<typeof LanguagePreferenceLiteral>;

export const UserLanguagePreference = {
  ...LanguagePreference,
  ...({ SKIPPED: LANGUAGE_SKIPPED } as const),
};

export type UserLanguagePreference = EnumValues<typeof UserLanguagePreference>;

export const UseCaseLiteral = Union(
  Literal("EDA"),
  Literal("BI"),
  Literal("INTERNAL_TOOLS"),
  Literal("ML"),
  Literal("DATA_PIPELINING"),
  Literal("OTHER"),
);

export const UseCase = getNormalEnum(UseCaseLiteral);
export type UseCase = Static<typeof UseCaseLiteral>;

export const CollaborationIntentLiteral = Union(
  Literal("JUST_ME"),
  Literal("WITH_A_TEAM"),
);

export type CollaborationIntent = Static<typeof CollaborationIntentLiteral>;
export const CollaborationIntent = getNormalEnum(CollaborationIntentLiteral);

export const WorkspaceIntentLiteral = Union(
  Literal("JOB"),
  Literal("SCHOOL"),
  Literal("PERSONAL"),
);

export const WorkspaceIntent = getNormalEnum(WorkspaceIntentLiteral);
export type WorkspaceIntent = Static<typeof WorkspaceIntentLiteral>;

export const Persona = RRecord({
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- runtypes
  language: LanguagePreferenceLiteral.Or(Null).Or(Literal(LANGUAGE_SKIPPED)),
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- runtypes
  useCases: Array(UseCaseLiteral).optional(),
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- runtypes
  useCaseOtherValues: Array(String).optional(),
});
export type Persona = Static<typeof Persona>;

export const PersonaDefault = {
  language: null,
};

// Only show onboarding to users created after this date.
// This is because in https://github.com/hex-inc/hex/pull/18263,
// we intentionally simplified some logic around onboarding causing a side effect where old users
// who hadn't previously might now see onboarding. To prevent that, we just check that users were created
// near or after that change.
const CREATOR_ONBOARDING_CUTOFF = new Date("2023-10-23T00:00:00Z");

interface UserFields {
  createdDate: Date;
  orgRole: OrgRole;
  persona: Persona | null;
}
export const showCreatorOnboarding = ({
  createdDate,
  orgRole,
  persona,
}: UserFields): boolean => {
  return (
    createdDate > CREATOR_ONBOARDING_CUTOFF &&
    isOrgRoleSuperset(orgRole, OrgRole.EDITOR) &&
    !!persona &&
    persona.language == null
  );
};
