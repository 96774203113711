import {
  Array,
  Literal,
  Number,
  Record,
  Static,
  String,
  Union,
} from "runtypes";

import { getNormalEnum } from "../runtypeEnums.js";

export const AugmentModelLiteral = Union(
  Literal("roguesl-v2-16b-seth6-16-1-p512"),
);
export type AugmentModel = Static<typeof AugmentModelLiteral>;
export const AugmentModel = getNormalEnum(AugmentModelLiteral);

export const AugmentModelConfig = Record({
  model: AugmentModelLiteral,
  params: Record({
    maxTokens: Number,
  }),
});

export type AugmentModelConfig = Static<typeof AugmentModelConfig>;

export const AugmentCompletionResponse = Record({
  text: String,
  completion_items: Array(
    Record({
      text: String,
      skipped_suffix: String,
      suffix_replacement_text: String,
    }),
  ),
});
export type AugmentCompletionResponse = Static<
  typeof AugmentCompletionResponse
>;
